<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <MetaCard
            v-if="meta"
            :meta="meta"
          >
            <template #left>
              <VBtn
                depressed
                icon
                :to="{
                  name : Names.R_META_SEARCH
                }"
              >
                <VIcon>fal fa-chevron-left</VIcon>
              </VBtn>
            </template>
          </MetaCard>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import MetaCard from '../../components/meta/MetaCard.vue';

export default {
  name: 'MetaView',
  components: { MetaCard },
  inject: ['Names'],
  data() {
    return {
      meta: null,
    };
  },
  created() {
    this.findMeta();
  },
  methods: {
    findMeta() {
      const payload = {
        id: this.$route.params.userId,
        serviceId: this.$route.params.serviceId,
      };

      this.$di.api.Meta
        .getMeta(payload)
        .then((response) => {
          try {
            this.meta = JSON.parse(response.meta);
          } catch (e) {
            this.$di.notify.snackError('Не удалось распарсить мету');
          }
        })
        .catch(this.$di.notify.errorHandler);
    },
  },
};
</script>
