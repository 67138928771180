<template>
  <VCard>
    <VToolbar flat>
      <slot name="left" />

      <VToolbarTitle>
        Мета
      </VToolbarTitle>

      <VSpacer />
      <!-- TODO: экшены здесь -->
    </VToolbar>

    <VDivider />

    <VCardText>
      <VExpansionPanels
        accordion
        flat
      >
        <VExpansionPanel>
          <VExpansionPanelHeader>JSON</VExpansionPanelHeader>
          <VExpansionPanelContent>
            <code
              class="elevation-0 d-block"
              style="white-space: pre"
              v-text="meta"
            />
          </VExpansionPanelContent>
        </VExpansionPanel>
      </VExpansionPanels>
    </VCardText>
  </VCard>
</template>

<script>
import { DEFAULT_PERSON_META } from './common';

export default {
  name: 'MetaCard',

  props: {
    meta: {
      type: Object,
      default: () => ({ ...DEFAULT_PERSON_META }),
    },
  },
};
</script>
