const DEFAULT_PERSON_META = {
  name: {
    first: null,
    middle: null,
    last: null,
  },
  dob: null,
  gender: null,
};

const DEFAULT_PMS_META = {
  user_category: null,
};

export { DEFAULT_PERSON_META, DEFAULT_PMS_META };
